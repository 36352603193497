import BigButton from "../components/Buttons/BigButton";

const Header = () => (
  <header
    className="flex flex-row items-center mx-full border-b-2 border-gray"
    style={{ height: "15vh" }}
  >
    <div className="md:hidden overflow-x-auto overflow-hidden">
      <nav className="flex flex-row justify-center items-center mx-auto ">
        <BigButton
          toRoute="/upload"
          buttonText="Upload"
          color="ivory"
          backgroundColor="button-pink"
        />
        <BigButton
          toRoute="/gallery"
          buttonText="Gallery"
          color="ivory"
          backgroundColor="button-blue"
        />
        <BigButton
          toRoute="/feed"
          buttonText="Feed"
          color="ivory"
          backgroundColor="button-purple"
        />
        <BigButton
          toRoute="/"
          buttonText="Home"
          color="ivory"
          backgroundColor="button-orange"
        />
      </nav>
    </div>
    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
      <nav className="flex flex-row justify-center items-center mx-auto">
        <BigButton
          toRoute="/upload"
          buttonText="Upload"
          color="ivory"
          backgroundColor="button-pink"
        />
        <BigButton
          toRoute="/gallery"
          buttonText="Gallery"
          color="ivory"
          backgroundColor="button-blue"
        />
        <BigButton
          toRoute="/feed"
          buttonText="Feed"
          color="ivory"
          backgroundColor="button-purple"
        />
        <BigButton
          toRoute="/"
          buttonText="Home"
          color="ivory"
          backgroundColor="button-orange"
        />
      </nav>
    </div>
  </header>
);

export default Header;
