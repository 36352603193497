import React from "react";

import DetailNFT from "./../ModifiedNFTE/DetailNFT";

const DetailPage = () => {
  return (
    <div className="flex-1 flex overflow-hidden h-full">
      <div className="flex flex-1 justify-center items-center overflow-y-scroll ">
        <DetailNFT />
      </div>
    </div>
  );
};

export default DetailPage;
