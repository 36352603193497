import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import six from "../../assets/six.png";

function UploadPage() {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 20,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const thumbs = files.map(file => (
    <div className="thumb" key={file.name}>
      <div className="thumb-inner">
        <img src={file.preview} className="upload-img" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const uploadFilePromise = file => {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("uploaded_file", file);
      axios
        .post("http://localhost:5000/ipfs/", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  };

  const uploadOnClick = () => {
    let filePromises = files.map(file => uploadFilePromise(file));
    Promise.all(filePromises).then(resp => {
      console.log("resp", resp);
      setFiles([]);
    });
  };

  return (
    <div className="flex-1 flex overflow-hidden h-full">
      <div className="flex justify-center items-center overflow-y-scroll"></div>
      <section className="flex flex-col items-center justify-center mx-auto h-full">
        <h1 className="text-3xl italic font-mono pb-8">l’appel du vide</h1>
        {files.length > 0
          ? null
          : // Make the spinning logo shrink and dissapear, then return
            // Have the actual confirm upload be a modal / alert
            // <button className="upload-button" onClick={uploadOnClick}>
            //   upload
            // </button>
            null}
        <div
          {...getRootProps({
            className:
              "w-2/3 h-auto flex items-center justify-center lg:w-5/6 lg:h-5/6"
          })}
        >
          <input {...getInputProps()} multiple />
          <img
            //className="w-auto h-5/6"
            className="w-48	h-48 lg:w-1/3 lg:h-1/3"
            src={six}
            style={{ animation: `spin 27s linear infinite` }}
          />
        </div>
        {/* <div className="upload-section">
        {files.length > 0 ? (
          <div className="thumb-container">{thumbs}</div>
        ) : null}
      </div> */}
        <span className="text-5xl pt-6">↑</span>
        <h1 className="text-4xl font-mono pt-4">click</h1>
      </section>
    </div>
  );
}

export default UploadPage;
