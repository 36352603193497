import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import UploadPage from "./components/Pages/UploadPage";
import GalleryPage from "./components/Pages/GalleryPage";
import AboutPage from "./components/Pages/AboutPage";
import HomePage from "./components/Pages/HomePage";
import FeedPage from "./components/Pages/FeedPage";
import DetailPage from "./components/Pages/DetailPage";

import Header from "./components/Header";

import StickyRollTut from "./components/Scroll/StickyRollTut";

function App() {
  return (
    <body
      className="flex flex-col mx-auto bg-ivory w-full h-full"
      //style={{ maxHeight: "100vh" }}
    >
      <Router>
        <Header />
        <main className="flex overflow-y-scroll" style={{ height: "85vh" }}>
          <div className="container mx-auto">
            <Switch>
              <Route exact path="/upload">
                <UploadPage />
              </Route>
              <Route path="/detail">
                <DetailPage />
              </Route>
              <Route exact path="/gallery">
                <GalleryPage />
              </Route>
              <Route exact path="/feed">
                <FeedPage />
              </Route>
              {/* <Route exact path="/about">
                <AboutPage />
              </Route> */}
              <Route path="/">
                <AboutPage />
              </Route>
            </Switch>
          </div>
        </main>
        {/* <footer
          style={{ height: "5vh" }}
          className="flex flex-row items-center justify-center text-center border-t-2 border-gray"
        >
          <Link to="/" className="">
            <h1 className="italic font-bold text-3xl xl:text-8xl">Hypercube</h1>
          </Link>
        </footer> */}
      </Router>
    </body>
  );
}

export default App;
