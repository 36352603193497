import React from "react";
import { NFTE } from "@nfte/react";

const NFTList = ({ title }) => {
  return (
    <div className="flex-1 flex overflow-hidden h-full">
      <div className="flex-1 justify-center items-center overflow-y-scroll">
        <ul className="flex flex-col items-center justify-center px-4 pb-4">
          <li className="py-2 w-full items-center">
            <NFTE />
          </li>
          <li className="py-2 w-full items-center">
            <NFTE />
          </li>
          <li className="py-2 w-full items-center">
            <NFTE />
          </li>
          <li className="py-2 w-full items-center">
            <NFTE />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NFTList;
