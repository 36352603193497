import React from "react";
import { Link } from "react-router-dom";

const bigButtonStyle = {
  width: "10rem",
  height: "3rem",
  margin: "1rem",
  "border-width": "0px",
  "text-decoration": "none",
  display: "flex",
  "flex-direction": "column",
  "justify-content": "center",
  "align-items": "flex",
  "text-align": "center"
};

const BigButton = ({ toRoute, buttonText, color, backgroundColor }) => {
  // TEMP STYLING FIX, INLINING CUSTOM CSS
  return (
    <Link
      style={bigButtonStyle}
      to={toRoute}
      className={`small-button bg-${backgroundColor} text-${color}`}
    >
      <span className="font-bold text-xl no-underline">{buttonText}</span>
    </Link>
  );
};

export default BigButton;
