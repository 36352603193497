import React from "react";

import four from "../../assets/four.png";

const AboutPage = () => {
  return (
    <div className="flex-1 flex items-center overflow-hidden justify-center">
      <div className="flex-1 justify-center items-center overflow-y-scroll">
        <div className="flex justify-center h-auto py-6">
          <img
            className="w-48	h-48 lg:w-1/3 lg:h-1/3"
            //w-7/12	h-auto
            src={four}
            style={{ animation: `spin 18s linear infinite` }}
          />
        </div>

        <div className="text-center font-semibold text-4xl py-4">
          Step into the <span className="italic">Hypercube</span>
        </div>

        <div className="flex justify-center">
          <ul className="pb-4 text-lg text-center px-8 pt-4 md:text-4xl lg:px-36">
            <li className="font-mono italic text-sm py-2">
              Create digital art as an NFT{" "}
            </li>

            <li className="font-mono italic text-sm py-2">
              Browse other hypercubers' interdimensional creations
            </li>

            <li className="font-mono italic text-sm py-2">
              Keep a cut of every sale
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
