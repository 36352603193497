import React from "react";
import { NFTE } from "@nfte/react";

const MyNFTE = ({
  contract,
  tokenId,
  darkMode = false,
  className = "w-3/4"
}) => {
  return (
    <NFTE
      darkMode={darkMode}
      className={className}
      contract={contract}
      tokenId={tokenId}
    />
  );
};

export default MyNFTE;
