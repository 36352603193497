import MyNFTE from "./MyNFTE";

const DetailNFT = ({
  contract,
  tokenId,
  darkMode = false,
  className = "w-3/4"
}) => {
  return (
    <MyNFTE
      contract={contract}
      tokenId={tokenId}
      darkMode={darkMode}
      className={className}
    />
  );
};

export default DetailNFT;
